body {
    background-color: rgb(44, 46, 50);
}

* {
    font-family: "Sora", sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*::selection {
    background: #b6bfbf;
}